import {
  IconArrowRight,
  IconPayslip,
  InvoiceCard,
  ResumenInvoice,
} from '@Knowledge-OTP/znk-ui-components'
import React, {useMemo, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'
import usePayslips from '../../../Model/Users/Educators/GetPayslips'
import moment from 'moment'
import useTranslation from '../../../i18n/useTranslation'
import {Collapse, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import PayslipModal from './PayslipModal'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import PaymentHistoryModal from '../../Profile/Pyments/history'

const PayslipsCard = ({setMouseOver}) => {
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const isPayslips = true
  const info = useSelector((state) => state.user.info)
  const [collapse, SetCollapse] = useState(false)
  const [activeTab, setActiveTab] = useState('summary')
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen)
  }
  const userId = info.id
  const {data, loading} = usePayslips(
    {
      limit: 0,
      userId: userId,
      status: ['pendingPayment', 'paid'],
      sortField: 'payslip',
    },
    () => {},
    console.log,
  )
  const payslipsData = data && data.docs
  const nextPay = useMemo(() => {
    if (data && data.docs) {
      if (data.docs.filter((e) => e.status === 'pendingPayment').length > 0)
        return {
          next: true,
          payslip: data.docs.filter((e) => e.status === 'pendingPayment')[0],
        }
      else return {next: false, payslip: {}}
    }
    return {next: false, payslip: {}}
  }, [data])
  const more = []
  const openModal = (id) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'payslips.viewPayslip',
      data: {isOpen: true, payslipId: id},
    })
  }
  const viewPayslip = () => {
    if (nextPay.next === false) return
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'payslips.viewPayslip',
      data: {isOpen: true, payslipId: nextPay.payslip.id},
    })
  }

  return (
    <>
      <div className='w-100 px-3 px-sm-5 px-md-7'>
        {loading ? (
          <Skeleton height={190} count={1} className='w-100' />
        ) : (
          <div className='mt-4' onClick={viewPayslip}>
            <InvoiceCard
              empty={!nextPay.next}
              type='normal'
              text={`${
                data && data.docs && nextPay.next
                  ? translate('student.modal.payments.endOf')
                  : 'Nothing Due'
              } ${
                data && data.docs && nextPay.next
                  ? moment(nextPay.payslip.to).format('MMMM')
                  : ''
              }`}
              textTop='Next pay:'
              typePayment=''
              amount={
                data && data.docs && nextPay.next
                  ? nextPay.payslip.payslipProps.amount
                  : 0
              }
              invoice={`PAY-${
                data && data.docs && nextPay
                  ? String(nextPay.payslip.payslipNumber).padStart(5, 0)
                  : ''
              }`}
              emptyText=''
            />
          </div>
        )}
        <PaymentHistoryModal
          {...{loading, payslipsData, isOpen, toggle, isPayslips}}
        />
        {/* <div className='mx-0 mt-6 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>
            {translate('student.modal.payments.lastpayment')}
          </span>
          <span className='text-gray'>
            {translate('student.modal.payments.viewHistory')}
          </span>
        </div> */}
        <Nav className='w-100 mx-0 mt-6 mb-3 p-0 d-flex flex-row justify-content-between'>
          <NavItem className='nav-title mb-2' active={activeTab === 'summary'}>
            <NavLink
              className='pl-0'
              onClick={() => {
                setActiveTab('summary')
              }}
            >
              {translate('student.modal.payments.lastpayment')}
            </NavLink>
          </NavItem>
          <NavItem className='nav-title' active={activeTab === 'history'}>
            <NavLink
              className='pr-0'
              onClick={() => {
                toggle()
              }}
            >
              {translate('student.modal.payments.viewHistory')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className=''>
          <TabPane tabId='summary'>
            {data &&
              data.docs &&
              data.docs
                .filter((e) => e.status === 'paid')
                .map((ele, index) => {
                  if (index >= 3) {
                    more.push(ele)
                  }
                  return index < 3 ? (
                    <ResumenInvoice
                      key={index}
                      type='green'
                      text={`${translate(
                        'student.modal.payments.endOf',
                      )} ${moment(ele.to).format('MMMM')}`}
                      className='mx-0 my-1 px-2 py-1'
                      amount={ele.payslipProps.amount}
                      icon={<IconPayslip color='graytext' className='mr-3' />}
                      onClick={() => openModal(ele.id)}
                    />
                  ) : null
                })}
            <Collapse isOpen={collapse}>
              {more.map((ele, index) => {
                return index <= 2 || true ? (
                  <ResumenInvoice
                    key={index}
                    type='green'
                    text={`${translate(
                      'student.modal.payments.endOf',
                    )} ${moment(ele.to).format('MMMM')}`}
                    className='mx-0 my-1 px-2 py-1'
                    amount={ele.payslipProps.amount}
                    icon={<IconPayslip color='graytext' className='mr-3' />}
                    onClick={() => openModal(ele.id)}
                  />
                ) : null
              })}
            </Collapse>
            {more.length > 0 && (
              <div
                style={{cursor: 'pointer'}}
                className='znk-card empty mx-0 my-1 px-2 py-1'
                onClick={() => {
                  SetCollapse(!collapse)
                }}
              >
                <div className='left'>
                  <div className='h45 text-gray'>
                    {!collapse
                      ? translate('student.modal.payments.viewAll')
                      : translate('student.modal.payments.viewLess')}
                  </div>
                </div>
                <div>
                  <IconArrowRight size={15} className='text-gray' />
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tabId='history'>
            {more.map((ele, index) => {
              return index > 2 ? (
                <ResumenInvoice
                  key={index}
                  type='green'
                  text={`${translate('student.modal.payments.endOf')} ${moment(
                    ele.to,
                  ).format('MMMM')}`}
                  className='mx-0 my-1 px-2 py-1'
                  amount={ele.payslipProps.amount}
                  icon={<IconPayslip color='graytext' className='mr-3' />}
                />
              ) : null
            })}
          </TabPane>
        </TabContent>
      </div>
      <PayslipModal />
    </>
  )
}

export default PayslipsCard
