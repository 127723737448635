import React from 'react'
import {
  ListItem,
  IconDate,
  IconInfo,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'


const CheckInType = {
    scoreExpectation: 'home.onboard.approve.types.scoreExpectation',
    regular: 'home.onboard.approve.types.regular',
    undefined: 'home.onboard.approve.types.regular'
}


const CheckInsPlan = (props) => {
    const {data = []} = props
    const {translate} = useTranslation()
    return (
        <div className='my-5 mx-0'>
            <div className='w-100 d-flex flex-row align-items-center justify-content-between'>
                <h3>
                    <b>{translate('home.onboard.approve.checkInsTitle')}</b>
                </h3>
                <ToolTipInfo
                    type={'dark'}
                    place={'left'}
                    innerElement={
                        <p className='p-0 m-0' style={{maxWidth: '200px'}}>
                            {translate('home.onboard.approve.checkInsInfo')}
                        </p>
                    }
                >
                    <div className='hover-icon'>
                        <IconInfo className='text-gray' size={16}/>
                    </div>
                </ToolTipInfo>
            </div>
            <div className='m-0 p-0 my-4'>
                {data ? (
                <>
                    {
                        data.map(
                            (check, index) => {
                                const {date, type} = check
                                return (
                                    <ListItem
                                        key={index}
                                        showTopLine={false}
                                        className='pl-0 py-1'
                                        Left={
                                        <div className='d-flex flex-row align-items-center text-muted'>
                                            <IconDate size={20}/>
                                            <span className='ml-4 text-truncate'>
                                                {moment(date).format('MMMM Do, YYYY')}
                                            </span>
                                        </div>
                                        }
                                        Right={<span className='text-truncate'>{translate(CheckInType[type])}</span>}
                                    />
                                )
                            }
                        )
                    }     
                </>
                ) : (
                <Skeleton count={4} />
                )}
            </div>
        </div>
    )
}

export default CheckInsPlan
