import React, {useMemo, useState, useContext} from 'react'
import {
  Avatar,
  IconCountry,
  IconStudent,
  IconProfile,
  Rating,
  Modal,
  IconClose,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {Modal as ModalR, ModalBody} from 'reactstrap'
import {defaultProps, propTypes} from '../propTypes'
import {useSelector} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import UserContext from '../../../../contexts/UserContext'
import EditAvatar from '../../../../common/EditAvatar'
import './index.css'

const UserCard = (props) => {
  const Roles = [].concat(props.roles)
  const countries = useSelector((state) => state.countries.countries)
  const {refetch} = useContext(UserContext)
  const {
    emails,
    firstName,
    lastName,
    countryId,
    studentInfo,
    phone,
    educatorInfo,
    avatar: avatarUser,
  } = props
  const reduxInfo = useSelector((state) => state.user.info)
  const userId = reduxInfo?.id
  const fullName = `${firstName} ${lastName}`
  const {setDefaultLang, translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const user = useMemo(() => {
    let isStudent = false
    let isEducator = false
    if (Roles) {
      if (Roles.filter((e) => e === 'STUDENT').length > 0) {
        isStudent = true
      } else if (Roles.filter((e) => e === 'EDUCATOR')) {
        isEducator = true
      }
      return {
        isStudent,
        isEducator,
      }
    }
    return {
      isStudent: false,
      isEducator: false,
    }
  }, [Roles])
  const parents =
    studentInfo && studentInfo.parents && studentInfo.parents
      ? studentInfo.parents
      : []
  const myParent = parents.find((parent) => parent.managePayments === true)
  const [isReset, setIsReset] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const toggleCrop = () => {
    setOpenCrop(!openCrop)
  }
  const onChangeAvatar = (file) => {
    setOpenCrop(true)
  }
  const fetch = async () => {
    await refetch()
    setIsReset(isReset === null ? true : !isReset)
  }
  return (
    <>
      {breakWidth !== 'SM' && openCrop ? (
        <Modal
          className='modal-centered modal-colored white-modal pr-3 pl-3 '
          isOpen={openCrop}
          toggle={toggleCrop}
          backdrop='static'
          style={{height: '95%'}}
          StyleModalBody={{height: '100%'}}
          contentClassName='contentHeight'
          Head={
            <div className='modal-header p-0 m-0'>
              <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-5'>
                <span className='text-title h2'>
                  {translate('profile.actions.changeAvatar.title')}
                </span>
                <IconClose onClick={toggleCrop} className='text-gray' />
              </div>
            </div>
          }
        >
          {/* <CropAvatar userId={null} fetch={fetch} toogle={toggleCrop} /> */}
          <EditAvatar userId={userId} fetch={fetch} toogle={toggleCrop} />
        </Modal>
      ) : (
        openCrop && (
          <ModalR
            toggle={toggleCrop}
            isOpen={openCrop}
            style={{height: '98%'}}
            contentClassName='contentHeight'
          >
            <div className='modal-header p-0 m-0'>
              <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                <span className='text-title h2'>
                  {translate('profile.actions.changeAvatar.title')}
                </span>
                <IconClose onClick={toggleCrop} className='text-gray' />
              </div>
            </div>
            <ModalBody
              className='p-0 m-0 contentHeight'
              style={{height: '100%'}}
            >
              {/* <CropAvatar userId={userId} fetch={fetch} toogle={toggleCrop} /> */}
              <EditAvatar userId={userId} fetch={fetch} toogle={toggleCrop} />
            </ModalBody>
          </ModalR>
        )
      )}
      <div className='d-flex flex-column mt-2'>
        <Avatar
          name={fullName}
          uri={avatarUser}
          named={false}
          size='avatar-extra'
          activateChange
          onClickImage={onChangeAvatar}
          isReset={isReset}
        />
        <span className='h2 font-weight-bold mt-4'>{fullName}</span>
        <span className='h4 text-secondary font-weight-light mt-2'>
          {emails.length ? emails[0].address : '-'}
        </span>
        {user.isStudent && (
          <span className='d-flex text-secondary small mt-2'>
            <span
              className='mr-3 d-flex align-items-center'
              onClick={() => setDefaultLang('keys')}
            >
              <IconStudent size={12} className='mr-1' />
              <span className='infoStudent'>{studentInfo.grade.slice(1)}</span>
            </span>
            {myParent && (
              <span className='mr-3 d-flex align-items-center'>
                <IconProfile size={12} className='mr-1' />
                <span className='infoStudent'>{`${myParent.firstName} ${myParent.lastName}`}</span>
              </span>
            )}
            <span className='mr-3 d-flex align-items-center'>
              <IconCountry size={12} className='mr-1' />
              <span className='m-0 p-0 infoStudent'>
                {countryId && countries[countryId]
                  ? countries[countryId].name
                  : '-'}
              </span>
            </span>
          </span>
        )}
        {user.isEducator && (
          <>
            <span className='h3 text-secondary font-weight-light mt-2'>
              {phone}
            </span>
            <div className='d-flex flex-row align-items-center mt-3'>
              <Rating
                mode='results'
                initialRating={educatorInfo.rating}
                className='mr-2'
                size={24}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}
UserCard.propTypes = propTypes
UserCard.defaultProps = defaultProps
export default UserCard
