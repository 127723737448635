import React, {useState} from 'react'
import {useQuery} from '@apollo/client'
import {Nav, NavItem, NavLink} from 'reactstrap'
import {InvoiceCard, Notification, BlankSpace} from '@Knowledge-OTP/znk-ui-components'
import './index.css'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import InvoiceDetails from './InvoiceDetails'
import invoicesQuery from './invoicesQuery'
import PaymentHistoryModal from './history'
import {useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import PendingPaymentsModal from './PendingPayments'
import InvoiceModal from './InvoiceDetails/Modal'
import useModalState from '../../../hooks/useModalState'
import PaymentsListItem from './history/PaymentsListItem'
import historyQuery from './history/historyQuery'

const PaymentsCard = ({setMouseOver}) => {
  const [isOpen, setIsOpen] = useState(false)
  // Cantidad de invoices que queremos mostrar en el listado de most recently payments
  const historyToShow = 3
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen)
  }
  const {onChange} = useModalState('payments.viewInvoice')
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {data, loading, refetch} = useQuery(invoicesQuery)
  const paymentsData = data && data.myInvoices.docs
  console.log('ttt paymentsData', paymentsData)
  const pendingInvoices = paymentsData
    ? paymentsData.filter(
        (invoice) =>
          (!['procesingPayment', 'pendingPayment'].includes(invoice.status) &&
            moment(invoice.to) < moment()) ||
          invoice.status === 'unPaid',
      )
    : []
  const {data: dataHistory, loading: loadingHistory} = useQuery(historyQuery, {
    variables: {
      status: ['paid', 'pendingPayment', 'procesingPayment'],
      category: ['livePrep', 'counseling'],
      dates: [''],
    },
    fetchPolicy: 'network-only',
  })
  const historyData = dataHistory ? dataHistory.getInvoicePayments : []
  const historyDataToShow = historyData.slice(0, historyToShow)

  const upcomingLivePrep =
    paymentsData &&
    paymentsData.length &&
    paymentsData.find((invoice) => invoice.category === 'livePrep')
  const upcomingCounseling =
    paymentsData &&
    paymentsData.length &&
    paymentsData.find((invoice) => invoice.category === 'counseling')

  return (
    <>
      <div className='w-100 px-3 px-sm-5 px-md-7'>
        {pendingInvoices.length ? (
          <>
            <Notification
              className='znk-danger'
              label={translate('profile.payments.pendingInvoices')}
              number={pendingInvoices.length}
              onClick={() => {
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'profile.pendingPayments',
                  data: {isOpen: true, invoices: pendingInvoices},
                })
              }}
            />
            <PendingPaymentsModal />
          </>
        ) : null}
        <PaymentHistoryModal
          {...{loading, paymentsData, isOpen, toggle, setMouseOver}}
        />
        <div className='pt-2'>
          {loading ? (
            <Skeleton height={70} />
          ) : upcomingCounseling ? (
            <div
              onClick={() =>
                onChange(() => ({invoiceId: upcomingCounseling.id}))
              }
            >
              <InvoiceCard
                type={
                  ['canceled', 'unPaid'].includes(upcomingCounseling.status)
                    ? 'warn'
                    : upcomingCounseling.category === 'livePrep'
                    ? 'normal'
                    : 'dark'
                }
                textTop={
                  upcomingCounseling.status === 'unPaid'
                    ? 'Awaiting Payment'
                    : translate('profile.payments.nextPayment')
                }
                text={
                  upcomingCounseling.status !== 'unPaid'
                    ? upcomingCounseling.description &&
                      upcomingCounseling.description !== ''
                      ? upcomingCounseling.description
                      : `End of ${moment
                          .utc(upcomingCounseling.to)
                          .format('MMMM')}`
                    : null
                }
                className='mt-1'
                amount={upcomingCounseling.amount.toFixed(2)}
                invoice={`INV-${String(
                  upcomingCounseling.invoiceNumber,
                ).padStart(5, 0)}`}
              />
            </div>
          ) : upcomingLivePrep ? (
            <InvoiceCard
              onClick={() => onChange(() => ({invoiceId: upcomingLivePrep.id}))}
              type={
                ['canceled', 'unPaid'].includes(upcomingLivePrep.status)
                  ? 'warn'
                  : upcomingLivePrep.category === 'livePrep'
                  ? 'normal'
                  : 'dark'
              }
              textTop={
                upcomingLivePrep.status === 'unPaid'
                  ? 'Awaiting Payment'
                  : translate('profile.payments.nextPayment')
              }
              text={
                upcomingLivePrep.status !== 'unPaid'
                  ? upcomingLivePrep.description &&
                    upcomingLivePrep.description !== ''
                    ? upcomingLivePrep.description
                    : `End of ${moment.utc(upcomingLivePrep.to).format('MMMM')}`
                  : null
              }
              className='mt-1'
              amount={upcomingLivePrep.amount.toFixed(2)}
              invoice={`INV-${String(upcomingLivePrep.invoiceNumber).padStart(
                5,
                0,
              )}`}
            />
          ) : (
            <InvoiceCard
              empty
              text={translate('profile.payments.noPaymentsDue')}
            />
          )}
          <Nav tabs className='payments-nav mt-5'>
            <NavItem>
              <span className='text-title' style={{cursor: 'pointer'}}>
                {translate('profile.actions.payments.upcomming')}
              </span>
            </NavItem>
          </Nav>

          <div className='container-fluid pt-4 px-0'>
            {loading ? (
              <Skeleton height={20} count={3} />
            ) : data && paymentsData.length ? (
              <>
                {paymentsData.map((element, index) => {
                  return <InvoiceDetails key={index} invoice={element} />
                })}
              </>
            ) : (
              <BlankSpace 
              style={{height: '100px'}}
              iconClassName={`mb-0 mt-4`}
              textClassName={'pt-3 px-5 pb-5'}
              className={`w-100 text-center`} mainText={translate('profile.payments.noItems')}/>
            )}
          </div>
          <Nav tabs className='payments-nav mt-5'>
            <NavItem className='my-auto'>
              <span className='text-title' style={{cursor: 'pointer'}}>
                {translate('profile.actions.payments.mostRecently')}
              </span>
            </NavItem>
            <NavItem className='my-auto'>
              <NavLink
                onClick={() => {
                  toggle()
                }}
              >
                <small className='font-weight-light text-muted'>
                  {translate('profile.actions.payments.viewHistory')}
                </small>
              </NavLink>
            </NavItem>
          </Nav>

          <div className='container-fluid pt-4 px-0'>
            {loadingHistory ? (
              <Skeleton height={20} count={3} />
            ) : historyDataToShow && historyDataToShow.length ? (
              <>
                {historyDataToShow.map((invoice, index) => {
                  return <PaymentsListItem key={index} invoice={invoice} />
                })}
              </>
            ) : (
              <BlankSpace 
              style={{height: '100px'}}
              iconClassName={`mb-0 mt-4`}
              textClassName={'pt-3 px-5 pb-5'}
              className={`w-100 text-center`} mainText={translate('profile.payments.noItems')}/>
            )}
          </div>
        </div>
        <InvoiceModal refetch={refetch} />
      </div>
    </>
  )
}

export default PaymentsCard
