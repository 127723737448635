import React from 'react'
import SocialButton from './SocialButton'

const SocialSignin = (props) => {
  const {isSignup, isEducator} = props
  return (
    <div className='d-flex flex-column'>
      <SocialButton
        isEducator={isEducator}
        providerId='google.com'
        isSignin={!isSignup}
      />
      <SocialButton
        isEducator={isEducator}
        providerId='facebook.com'
        isSignin={!isSignup}
      />
    </div>
  )
}

export default SocialSignin
